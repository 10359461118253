#video-recorder-sending-analysis {
  .mdc-dialog__content {
    position: relative;
    padding-bottom: 0;
  }

  .progress-bar {
    position: absolute;
    left: 0;
    top: 8px;
    width: 100%;
  }
}
