//variable
@import "variables";

//reset, normalize, helper-class
@import "partials/normalize";

//components
@import "components/main";
@import "components/header";
@import "components/video";
@import "components/toolbox";
@import "components/video_infobox";
@import "components/painting";
@import "components/loader";
@import "components/full_error";
@import "components/modal_sending";

body {
  font-family: "Ubuntu";
  user-select: none;
}
