#painting_board {
  //background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  height: calc(100% - 30px);
  z-index: 2;
  width: 100%;
  top: 0;

  #canvas_parent {
    width: 100%;
    height: 100%;
    position: relative;
    background: transparent;

    canvas {
      position: absolute;
      left: 0;
      top: 0;
      cursor: none;
      width: 100%;
      height: calc(100vh - 71px);

      &.with-cursor {
        cursor: pointer;
      }
    }

    #mainCanvas {
      z-index: 0;
    }

    #secondaryCanvas {
      z-index: 1;
      pointer-events: none;
    }
  }
}