#toolbox_section {
  background: #847ba92e;
  width: 50px;

  .toolbox-gui {
    width: 50px;
  }

  .toolbox-gui-color {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    #toolbox-gui-color-input {
      position: absolute;
      width: 31px;
      height: 32px;
      opacity: 0.0;
      cursor: pointer;
    }

    .toolbox-gui-color__element {
      width: 30px;
      height: 30px;
      border-radius: 2px;
      display: block;
    }
  }

  .toolbox-gui-tools {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
  }

  .toolbox-gui-tools__element {
    width: 50px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    span {
      color: #847ba9;
      font-size: 25px;
    }

    &:hover {
      background: #80808042;
    }
  }

  .toolbox-gui-tools__element.is-active {
    background: #847ba9;
    position: relative;

    span {
      color: white;
    }
  }

  .toolbox-gui-tools__icon {
    max-width: 90%;
  }
}