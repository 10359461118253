#video_infobox_section {
  background: #d1cce2;
  width: 328px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button:disabled,
  button[disabled]{
    opacity: 0.3;
  }

  .video-author {
    font-size: 13px;
    text-align: center;
    border-bottom: 1px solid #00000036;
    padding-bottom: 9px;
    font-weight: bold;
    color: gray;
    margin-top: 3px;
  }

  .video-description-label {
    font-size: 10px;
    margin-bottom: 1px;
    color: rgb(0 0 0 / 60%);
  }

  .video-description-content {
    margin: 0;
    font-size: 12px;
  }

  .video-infobox-author-section {
    border-bottom: 1px solid rgba(0, 0, 0, .2117647059);
    padding-bottom: 10px;
    margin-bottom: 10px;
  }

  .video-recorder-toolbox {
    .video-recorder-timer {
      text-align: center;

      p {
        background: #847ba9;
        display: inline-block;
        color: white;
        border-radius: 15px;
        padding: 2px 17px;
      }
    }

    .video-recorder-tools {
      button {
        border-radius: 50%;
        color: white;
        background: #847ba9;
      }

      .video-recorder-record-controls {
        text-align: center;

        #video-recorder-start-btn, #video-recorder-pause-btn {
          width: 100px;
          height: 100px;
          font-size: 40px;
          left: 20px;
        }

        #video-recorder-start-btn {
          left: 0;
        }

        #video-recorder-pause-btn {
          display: none;
        }

        #video-recorder-delete-btn {
          display: none;
          width: 35px;
          height: 35px;
          font-size: 18px;
          top: 19px;
          padding: 9px;
          left: 16px;
        }
      }

      .video-recorder-mic {
        text-align: center;
        margin-top: 15px;

        .active-mic-icon {
          display: none;
        }

        .inactive-mic-icon {
          display: inline;
        }

        .active-mic {
          .active-mic-icon {
            display: inline;
          }

          .inactive-mic-icon {
            display: none;
          }
        }
      }
    }

    &.recording-started {
      #video-recorder-start-btn {
        display: none !important;
      }

      #video-recorder-pause-btn {
        display: inline-block !important;
      }

      #video-recorder-delete-btn {
        display: inline-block !important;
      }
    }

    &.recording-stopped {
      #video-recorder-start-btn {
        display: inline-block !important;
        left: 20px !important;
      }

      #video-recorder-pause-btn {
        display: none !important;
      }

      #video-recorder-delete-btn {
        display: inline-block !important;
      }
    }
  }

  .video-recorder-send {
    border-top: 1px solid rgba(0,0,0,.2117647059);
    padding-top: 10px;
    margin-top: 10px;
    text-align: center;

    button {
      background: #847ba9;
    }

    button:disabled,
    button[disabled]{
      color: white;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.9);
  }
}

.pulse {
  animation: pulse 1.2s infinite;
}

#video-recorder-confirm-send {
  #video-recorder-analysis-preview {
    width: 100%;
  }

  .video-recorder-finish-comment-container {
    margin-top: 7px;

    .mdc-text-field--textarea {
      width: 100%;
      height: 78px;
      font-size: 13px;

      textarea {
        font-size: 12px;
      }
    }
  }
}