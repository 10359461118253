#full_error {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.hidden {
    display: none;
  }

  &.analysis-is-done {
    div {
      display: none;
    }
    .analysis-is-done-message {
      display: block;
    }
  }

  &.video-not-found {
    div {
      display: none;
    }
    .video-not-found-message {
      display: block;
    }
  }

  button {
    color: white;
    background: #847ba9;
  }
}
