#header_section {
  background: $dark-color;
  padding: 10px;

  .header-container {
    display: flex;

    .header-title {
      width: 100%;

      .video-title {
        font-weight: 300;
        color: white;
        width: 100%;
        font-size: 18px;
        border-right: 1px solid white;
        margin: 0 84px 0 0;
        text-align: center;
      }
    }

    .header-logo {
      width: 410px;
      text-align: center;

      .logo {
        height: 21px;
      }
    }
  }
}