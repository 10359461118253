#video_section {
  background: black;
  width: 100%;
  position: relative;

  .video_container {
    margin: 0 auto;
    width: 100%;
    height: calc(100vh - 45px);
  }

  .vjs-playback-rate .vjs-menu {
    width: 49px;
    left: -9px;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    max-height: 200px;
  }

  *:focus {
    outline: none;
  }

  .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
    z-index: 10;
  }
}