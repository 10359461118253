#loader {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;

  &.hidden {
    display: none;
  }

  .spinner {
    position: relative;
    left: 33px;
  }

  .progress-bar {
    position: relative;
    left: -44px;
  }
}

.spinner {
  width: 90px;
  height: 90px;
  border: 8px solid #847BA9;
  border-bottom-color: #E8B8DB;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.progress-bar {
  font-size: 25px;
  color: #847ba9;
  min-width: 63px;
  text-align: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}